<template>
  <div v-if="isReady">
    <h3 class="font-normal text-base md:text-lg lg:text-xl title-border mb-4 md:mb-5 lg:mb-8">History</h3>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-4 md:gap-y-8 wrap-span pb-4 md:pb-6 lg:pb-8">
      <div v-for="(item, key) in paymentRequest.history" :key="key" class=" w-full" :class="item.status==='Re-sent' ? 'new-line' : ''">
        <div>
          <label class="text-dark-blue text-base leading-tight block w-full font-light">{{ labeler(item.status) }} {{ checkUser(item) }}</label>
          <span class="text-base leading-tight block pt-2 font-normal w-full">{{ moment(item.createdAt) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { sentryLog } from '../../../../../helper/sentryLog';
export default {
  props: {
    paymentRequest: { type: Object }
  },

  data: {
    isSubmitted: {},
    isReady: false,
    productConfigType: "",
  },

  methods: {
    moment(date) {
      return moment(date).format("DD MMM YYYY");
    },

    labeler(value = "Pending") {
      let status;
      switch (value) {
        case "Pending":
          status = "Sent";
          break;
        case "Info-required":
          status = "Info-required";
          break;
        case "Re-sent":
          status = "Re-sent";
          break;
        case "Expired":
          status = "Expired";
          break;
        case "Cancelled":
        case "Schedule cancelled":
          status = "Cancelled";
          break;
        case "Active":
          status = this.isSubmitted ? "Approved" : "Accepted";
          break;
        case "Submitted":
          status = this.isSubmitted && this.productConfigType == "TWO_PARTY" ? "Sent" : "Accepted";
          break;
        case "Completed":
        case "Complete":
          status = "Completed";
          break;
      }
      return status;
    },

    checkUser(item) {
      let userName;
      let value = item.userType ? item.userType : '';
      switch (value) {
        case "superAdmin":
          userName = item.adminUser && item.adminUser.fullName ? "by "+item.adminUser.fullName : '';
          break;
        case "admin":
          userName = item.merchantUser && item.merchantUser.fullName ? "by "+item.merchantUser.fullName : '';
          break;
        case "introducerUser":
          userName = item.merchantUser && item.merchantUser.systemName ? "by "+item.merchantUser.systemName : '';
          break;
        case "apiUser":
          userName = item.merchantUser && item.merchantUser.systemName ? "by "+item.merchantUser.systemName : '';
          break;
        default:
      }
      return userName;
    },

     controlHistoryAccordingToCondition() {
      try {
        if (this.paymentRequest.requestOptions.includes("pay-later")) {
          this.productConfigType = this.paymentRequest.selectedPaymentPlan ? this.paymentRequest.selectedPaymentPlan.currentPlan.productConfigType : this.paymentRequest.payLaterPlan[0].currentPlan.productConfigType;
          this.isSubmitted = this.paymentRequest.history.find(item => item.status == "Submitted");
          const active = this.paymentRequest.history.find(item => item.status == "Active");
          if (this.productConfigType == "THREE_PARTY" || this.productConfigType == "TWO_PARTY") {
            if (!this.isSubmitted && active) {
              this.paymentRequest.history.splice(this.productConfigType == "TWO_PARTY" ? 0 : 1, 0, { createdAt: active.createdAt, status: "Submitted" });
              this.isSubmitted = { createdAt: active.createdAt, status: "Submitted" };
            }
          }
        }
        this.isReady = true;
      } catch(ex) {
        sentryLog(ex, { paymentRequest: this.paymentRequest });
      }
    }

  },

  created() {
    this.controlHistoryAccordingToCondition();
  }
};
</script>