<template>
  <div>
    <vx-card class="accordin-card mt-1">
      <div class="vx-card__body bsv p-0">
        <vs-collapse accordion>
          <vs-collapse-item ref="auditLogCollaspe">
            <div slot="header">
              <div  class="flex items-center">
                <h3 class="text-left card-title font-normal lg:text-2xl md:text-xl text-lg">Notes</h3>
              </div>
            </div>
            <div>
              <vs-button flat @click="openNoteModal"  v-round size="medium" class="mt-6">
                <plus-icon size="1.5x" class="custom-class"></plus-icon> New note
              </vs-button>   
              <div v-if="noteList.length > 0" class="timeline pt-10">
                <template v-for="(note, index) in noteList">
                  <div :key="index" class="pr-5 md:pr-10 timeline-items pl-4">
                    <div class="flex">
                      <S3ImageGenerator
                        v-if="note.user.profileImage !=='' && auditedByProfileImage(note)"
                        :document="auditedByProfileImage(note)"
                        :key="auditedByProfileImage(note)"
                        :is-base64="false"
                        :custom-class="'auditLog-icon rounded-full w-5 h-5'"
                      />
                      <div v-else>
                        <div class="w-5 h-5">
                          <SudoImage :text="initials(note.user)" :isNav="false" :isPreview="false"/>
                        </div>
                      </div>
                      <div class="ml-3 w-full mb-3">
                        <p class="text-xs mb-1">{{note.user.fullName}}</p>
                        <h4 class="text-xs mb-0">{{ `${dateFormat(note.createdAt)} at ${timeFormat(note.createdAt)} `}}</h4>
                        <p class="font-medium pt-4 text-xs leading-tight">NOTE</p>
                        <hr class="line-hr mt-1 mb-3"/>
                        <div style="background: #f8f8f8; padding: 8px 10px 14px 10px; border-radius: 8px;" class="mb-3">
                          <h6 class="text-base font-light leading-normal">{{note.note}}</h6>
                        </div>
                        <template v-if="note.files.length > 0">
                          <p class="font-medium pt-4 text-xs leading-tight">ATTACHMENTS</p>
                          <hr class="line-hr mt-1 mb-3"/>
                          <div v-for="(file, index) in note.files" :key="index" class="mb-2">
                            <S3FileGenerator :document="file"/>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </template>            
              </div>
              <div v-else class="pt-10 pb-4">
                <p>There are no notes yet.</p>
              </div>
            </div>
          </vs-collapse-item>
        </vs-collapse>
      </div>
    </vx-card>

    <vs-popup class="holamundo registerPopop" :active.sync="notePopup" title="New note" id="popupWrapperert">
      <div class="registerPopop--content">
        <div class="w-full">
          <div class="flex flex-col md:flex-row w-full">
            <div class="mb-4 md:mb-6 w-full md:6/12 lg:w-4/12 mr-0 md:mr-16 lg:mr-24">
              <label for="note" class="w-full text-base font-light text-dark-blue block pb-1">Note * </label>
              <vs-textarea
                v-model="noteDetail"
                counter="250"
                maxlength="250"
                rows="2"
                :counter-danger.sync="counterDanger"
                id="note"
                name="note"
                v-validate="'required'"
                class="w-full"
                style="max-width:330px;"
              />
              <span v-show="errors.has(`note`)" class="text-danger text-sm block">{{errors.first(`note`)}}</span>
            </div>
            <div class="mb-4 md:mb-6 w-full md:6/12 lg:w-4/12 mr-0 md:mr-16 lg:mr-24">
              <label for="note" class="w-full text-base font-light text-dark-blue block pb-1">Attachments</label>
              <input
                type="file"
                class="hidden"
                name="file"
                data-vv-as="file"
                multiple
                ref="uploadFileInput"
                @change="updateCurrFile($event)"
                accept=".pdf"
              />
              <div v-if="files.length > 0">
                <span v-for="(f, key) in files" :key="key" class="text-blue-light flex justify-between">{{ f.name }}
                  <span style="color:red" @click="clearFile(f.name, key)">
                    <trash-2-icon size="18" class="cursor-pointer"></trash-2-icon>
                  </span>
                </span>
              </div>
              <vs-button v-if="(files.length >= 3) ? false : true" class="only-border-btn mt-2" size="small" v-round @click="uploadFileInput()">Upload file</vs-button>
              <span v-show="errors.has(`fileType`)" class="text-danger text-sm block">{{ errors.first(`fileType`) }}</span>
              <span v-show="errors.has(`fileSize`)" class="text-danger text-sm block">{{ errors.first(`fileSize`)}}</span>
              <span v-show="errors.has(`fileLength`)" class="text-danger text-sm block">{{ errors.first(`fileLength`) }}</span>
            </div>
          </div>
        </div>
        <div class="flex justify-end mt-4 md:mt-6 lg:mt-8 pt-1">
          <vs-button class="mr-8" v-round size="large" @click="saveNote">Add</vs-button>
          <a class="text-xs flex items-center underline mid-blue" @click="closeNoteModal">Cancel</a>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import S3ImageGenerator from "@/views/components/S3ImageGenerator";
import S3FileGenerator from "@/views/components/S3FileGenerator";
import SudoImage from "@/views/components/SudoImage";
import { PlusIcon, Trash2Icon } from 'vue-feather-icons';

  export default {
    components: {
      S3ImageGenerator,
      S3FileGenerator,
      PlusIcon,
      SudoImage,
      Trash2Icon,
    },
    props: ['paymentRequest'],
    data() {
      return {
        notePopup: false,
        noteDetail: "",
        counterDanger: "",
        files: [],
        isPdfUpload: true,
        noteList: [],
        paymentRequestId: this.$route.params,
      }
    },
    methods:{
       ...mapActions("note", ["addNote", "fetchNote"]),
      openNoteModal() {
        this.notePopup = true;
        this.errors.clear();
      },
      closeNoteModal() {
        this.noteDetail = "";
        this.files = [];
        this.notePopup = false;
        this.errors.clear();
      },
      uploadFileInput() {
        this.$refs.uploadFileInput.click();
      },
      updateCurrFile(input, key) {
        this.files = input.target.files;
        this.validateFile(this.files);
      },
      validateFile(file) {

        if (file.length > 3) {
          this.errors.add({
            field: 'fileLength',
            msg: 'Only able to attach up to 3 PDF documents'
          });
          this.isPdfUpload = false;
          return;
        }
        for (let i = 0; i < file.length; i++) {
          this.isPdfUpload = true;
          if (file[i].type != 'application/pdf') {
            this.errors.add({
              field: 'fileType',
              msg: 'Please ensure file is a PDF document'
            });
            this.isPdfUpload = false;
          }
          if (file[i].size > 15 * 1024 * 1024) {
            this.errors.add({
              field: 'fileSize',
              msg: 'File size too large, must be less then 15MB'
            });
            this.isPdfUpload = false;
          }
        }
      },
      clearFile(name, index) {
        this.errors.clear();
        this.files = Object.values(this.files);
        this.files.splice(index, 1);
        this.validateFile(this.files);
        this.$refs.uploadImgInput.value = null;
      },
      async saveNote() {
        const validationState = await this.$validator.validate();
        if (!validationState || !this.isPdfUpload) {
          return false;
        }


        this.$vs.loading();
        const formData = new FormData();
        formData.append("note", this.noteDetail);
        formData.append("objectId", this.paymentRequest._id);
        formData.append("type", "paymentrequest");
        for (const i of Object.keys(this.files)) {
          formData.append('noteFiles', this.files[i]);
        }
        const payloadObj = {
          data: formData,
          config: {
            header: {
              "Content-Type": "multipart/form-data",
            },
          },
        };
        this.addNote(payloadObj).then((response) => {
          this.noteList = response.data.data;
          this.closeNoteModal();
          this.errors.clear();
          this.$vs.loading.close();
        }).catch((err) => {
          this.showErrorMessage(err.response.data.title, err.response.data.message)
          this.$vs.loading.close();
        });
      },

      showErrorMessage(title = 'Warning', message = 'Warning', time = false, colorClass = 'danger') {
        this.$toast.error(<div class="container"><h3>{title}</h3><p>{message}</p></div>, {
          position: "top-right",
          timeout: time ? 3000 : false,
          closeOnClick: false,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: {
            iconClass: `material-icons text-${colorClass}`,
            iconChildren: "info_outline",
            iconTag: "span"
          },
          rtl: false,
          message: message
        });
      },

      async getNotes() {
        this.$vs.loading();
        this.fetchNote(`${this.paymentRequestId.id}/paymentrequest`).then((response) => {
          this.noteList = response.data.data;
          this.$vs.loading.close();
        }).catch((err) => {
          this.showErrorMessage(err.response.data.title, err.response.data.message)
          this.$vs.loading.close();
        });
      },
      auditedByProfileImage(note) {
        return this.$lodashHelper.checkObjectKey(note, "user.profileImage", "");
      },
      dateFormat(date) {
        return moment(date).format("DD MMM YYYY");
      },
      timeFormat(date) {
        return moment(date).format("HH:mm");
      },
      initials(note) {
        return note.fullName ? `${note.fullName.split(' ').slice(0, -1).join(' ').charAt(0)} ${note.fullName.split(' ').slice(-1).join(' ').charAt(0)}` : "";
      },
    },
    mounted() {
      this.getNotes();
    }
  }
</script>
