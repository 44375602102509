<template>
  <vs-row>
    <vs-card class="mt-0" id="transactionWidget">
      <div slot="header">
        <h5 class="font-normal text-lg md:text-xl lg:text-2xl">Transactions</h5>
      </div>
      <div v-if="transactionBind">
        <vs-table v-if="paymentRequestDetail.transactions.length" :data="paymentRequestDetail.transactions" :max-items="10">
          <template slot="thead">
            <vs-th> Type </vs-th>
            <vs-th> Date </vs-th>
            <vs-th> Reference </vs-th>
            <vs-th> Amount </vs-th>
            <vs-th> Status </vs-th>
            <vs-th v-if="!paymentRequestDetail.isPaylaterTransaction && paymentRequestDetail.paymentStatus !== 'Refunded'"></vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr">
                <div class="flex items-center">
                  <img v-bind:src="tr.icon" width="30px" class="mr-2"/>
                  {{ tr.typeText }}
                </div>
              </vs-td>
              <vs-td :data="tr.dateFormatted">{{ tr.dateFormatted }}</vs-td>
              <vs-td :data="tr.reference">{{ tr.reference }}</vs-td>
              <vs-td :data="tr.amountFormatted">{{ tr.amountFormatted }}</vs-td>
              <vs-td :data="tr.status"><span :class="getStatusClass(tr.status)">{{ tr.status }}</span></vs-td>
              <vs-td :data="tr.status" v-if="isRefundable(tr)">
                <a @click="refundPopup(tr)" class="underline cursor-pointer">Refund</a>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <p v-else>No transactions to show yet.</p>
      </div>
      <div v-else><p>No transactions to show yet.</p></div>
    </vs-card>
    <vs-popup
      v-if="canRefund == true"
      class="refund-popup"
      :active.sync="refundPopupActive"
      @close="closeModal"
      :title="'Refund this transaction?'"
      :key="refundPopupKey"
      :button-close-hidden="true"
    >
      <vs-row class="mb-6 mt-5">
        <vs-col vs-w="3" vs-xs="12">
          <vs-radio vs-value="full" class="w-full" vs-name="refund_type" v-model="refundType">Full Refund</vs-radio>
        </vs-col>
        <vs-col vs-w="9" vs-xs="12">
          <vs-radio vs-value="partial" class="w-full" vs-name="refund_type" v-model="refundType">Partial Refund</vs-radio>
        </vs-col>
        <vs-col vs-w="12" class="mt-10">
          <vs-col vs-w="5" vs-xs="12" class="mr-5">
            <vs-row>
              <vs-col vs-w="12" v-if="refundType === 'partial'">
                <p><strong>Original amount: ${{ activeTransaction.amount }}</strong></p>
                <p><strong>Available to refund: ${{ remainingAmount }} </strong></p>
              </vs-col>
              <vs-col vs-w="12" class="mt-5">
                <div class="static-amount">
                  <money
                    v-model="amount"
                    name="amount"
                    :precision="2"
                    class="w-full amount-ph"
                    :placeholder="'Amount'"
                    :disabled="refundType === 'full'"
                    v-validate="'required||max_value:' + remainingAmount"
                  ></money>
                  <span class="text-danger text-sm" v-show="errors.has('amount')">{{ errors.first("amount") }}</span>
                </div>
              </vs-col>
              <vs-col vs-w="12" class="mt-5">
                <vs-input
                  class="w-full"
                  label-placeholder="Reference*"
                  placeholder="Reference*"
                  v-model="reference"
                  v-validate="'required'"
                  name="reference"
                  maxLength="20"
                />
                <span class="text-danger text-sm" v-show="errors.has('reference')">{{ errors.first("reference") }}</span>
              </vs-col>
              <p v-if="refundType === 'partial'" class="mt-5" style="font-size: 10px">
                Amounts processed as a "partial refund" must total less than the original payment amount.
              </p>
            </vs-row>
          </vs-col>
          <vs-col vs-w="5" vs-xs="12" class="float-right mr-10">
            <vs-row>
              <vs-col vs-w="12">
                <vs-input class="w-full" label-placeholder="Customer name" placeholder="Customer name" v-model="name" name="name" maxLength="20" />
                <span class="text-danger text-sm" v-show="errors.has('name')">{{ errors.first("name") }}</span>
              </vs-col>
              <vs-col vs-w="12" class="mt-5">
                <vs-input class="w-full" label-placeholder="Customer email" placeholder="Customer email" v-model="email" v-validate="'email'" name="email" />
                <span class="text-danger text-sm" v-show="errors.has('email')">{{ errors.first("email") }}</span>
              </vs-col>
              <vs-col class="mt-5 relative ph-phone" vs-w="12">
                <the-mask
                  :class="maskedMobileNumber ? 'hasValue' : ''"
                  :mask="['#### ### ###']"
                  v-model="maskedMobileNumber"
                  masked
                  name="mobile"
                  v-validate="{ regex: /^04\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3}$/ }"
                  placeholder=""
                />
                <span class="input-span-placeholder vs-input--placeholder large large vs-placeholder-label">
                  Customer mobile phone
                </span>
                <span class="text-danger text-sm" v-show="errors.has('mobile')">
                  {{ errors.first("mobile") }}
                </span>
              </vs-col>
            </vs-row>
          </vs-col>
        </vs-col>
        <vs-col class="mt-10">
          <vs-col vs-w="4" class="float-right">
            <vs-button color="primary" flat style="margin-top: 15px" @click="refundTransaction">Refund Transaction</vs-button>
            <a class="mt-4 ml-5" style="color: #ff0000" @click="closeModal">Cancel</a>
          </vs-col>
        </vs-col>
      </vs-row>
    </vs-popup>
  </vs-row>
</template>
<script>
import { mapActions } from "vuex";
import moment from "moment";
import { RRule } from "rrule";
import { Money } from "v-money";
import { TheMask } from "vue-the-mask";
import { Validator } from "vee-validate";

const dictionary = {
  en: {
    attributes: {
      mobile: "mobile phone",
    },
    messages: {
      regex: () => "Phone number should start with 04 and should be 10 digits",
      max_value: () => "You cannot refund more than original transaction amount",
    },
  },
};
Validator.localize(dictionary);

export default {
  name: "Transaction",
  props: ["paymentRequestDetail", "canRefund"],
  components: {
    TheMask,
    Money,
  },
  data() {
    return {
      refundedAmount: 0,
      remainingAmount: 0,
      refundPopupActive: false,
      activeTransaction: {},
      refundType: "full",
      amount: 0,
      reference: "",
      name: "",
      email: "",
      mobile: "",
      maskedMobileNumber: "",
      confirmation: false,
      refundPopupKey: 0,
      transactionBind: false,
      paymentRequestId:this.$route.params.id,
      portal: process.env.VUE_APP_NAME == "FlipPay" ? "Flip" : "Rello"
    };
  },
  mounted() {
    this.checkRequestType();
  },
  watch: {
    paymentRequestDetail(val) {
      if (val) {
        this.checkRequestType();
        var el = document.getElementById("transactionWidget");
        this.$scrollTo(el);
      }
    },
  },
  methods: {
    ...mapActions("transaction", ["refundPaymentRequestTransaction"]),
    checkRequestType() {
      this.transactionBind = false;

      if (this.paymentRequestDetail.requestType === "recurring") {
        this.prepareSchedule();
      }

      if (!this.paymentRequestDetail.selectedPaymentPlan && this.paymentRequestDetail.transactions) {
        const refundedTransactions = this.paymentRequestDetail.transactions.filter((transaction) => transaction.paymentStatus === "Refunded");
        const actualTransaction = this.paymentRequestDetail.transactions.find((transaction) => transaction.paymentStatus === "Completed");

        if (refundedTransactions.length) {
          this.refundedAmount = refundedTransactions.map((item) => Math.abs(parseFloat(item.amount))).reduce((prev, next) => prev + next, 0);
          this.remainingAmount = parseFloat(actualTransaction.amount) - this.refundedAmount;
        }
      }

      this.transactionBind = true;
    },
    prepareSchedule() {
      let rule = {
        dtstart: new Date(this.paymentRequestDetail.frequency.startDate),
        freq: this.paymentRequestDetail.frequency.every === "week" ? RRule.WEEKLY : RRule.MONTHLY,
        interval: parseInt(this.paymentRequestDetail.frequency.interval),
      };

      if (this.paymentRequestDetail.frequency.endType === "by") {
        rule.until = this.paymentRequestDetail.frequency.endDate ? this.paymentRequestDetail.frequency.endDate : new Date();
      } else {
        rule.count = this.paymentRequestDetail.frequency.endAfterNumberOfPayments ? parseInt(this.paymentRequestDetail.frequency.endAfterNumberOfPayments) : 1;
      }
    },
    refundPopup(transaction) {
      this.amount = this.remainingAmount;
      this.refundPopupActive = true;
      this.activeTransaction = transaction;
      this.name = this.paymentRequestDetail.payeeName;
      this.email = this.paymentRequestDetail.payeeEmail;
      this.maskedMobileNumber = this.paymentRequestDetail.payeePhone;
    },
    refundTransaction() {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          let phoneNumber = this.maskedMobileNumber;
          this.mobile = phoneNumber.split(" ").join("");

          let data = {
            refundType: this.refundType,
            amount: this.amount,
            reference: this.reference,
            name: this.name,
            email: this.email,
            mobile: this.mobile,
            transactionId: this.activeTransaction._id,
            paymentRequestId: this.activeTransaction.paymentRequestId,
          };
          if (this.canRefund) {
            this.$vs.loading();
            await this.refundPaymentRequestTransaction(data).then((result) => {
              this.$vs.loading.close();
              this.showMessage(
                "Success",
                "Transaction has been refunded successfully.",
                "success",
                "icon-check-circle"
              );
              this.closeModal();
              this.$emit("fetchData", this.$route.params.id);
            }).catch((ex) => {
              this.$vs.loading.close();
              this.showMessage(
                "Failed",
                "Transaction could not be refunded",
                "danger",
                "icon-close-circle"
              );
              this.closeModal();
            });
          } else {
            this.showMessage(
              "Failed",
              "Permission denied",
              "danger",
              "icon-close-circle"
            );
          }
        }
      });
    },
    closeModal() {
      this.refundPopupActive = false;
      this.activeTransaction = {};
      this.refundType = "full";
      this.amount = 0;
      this.reference = "";
      this.name = "";
      this.email = "";
      this.mobile = "";
      this.maskedMobileNumber = "";
      this.confirmation = false;
      this.refundPopupKey += 1;
    },
    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon
      });
    },

    getStatusClass(status) {
      let label = "";

      if (["Completed", "Successful"].includes(status)) {
        label = "label label-success";
      } else if (["Awaiting Approval", "Draft", "Pending", "Queued"].includes(status)) {
        label = "label label-warning";
      } else if (["Cancelled", "Failed", "Missed"].includes(status)) {
        label = "label label-danger";
      } else {
        label = "label label-primary";
      }

      return label;
    },

    isRefundable(tr) {
      const checkType = (this.paymentRequestDetail.requestType === "one-off" || !this.paymentRequestDetail.requestType);
      const rowStatus = !["Failed", "Refunded", "Rejected"].includes(tr.paymentStatus);
      const prStatus = this.paymentRequestDetail.paymentStatus !== "Refunded";
      const isPaylater = !this.paymentRequestDetail.isPaylaterTransaction;
      const result = (this.canRefund && checkType && rowStatus && prStatus && isPaylater);

      return result;
    }
  },
};
</script>

