<template>
  <div>
    <h3 class="font-normal text-base md:text-lg lg:text-xl title-border mb-4 md:mb-5 lg:mb-8">Product fields</h3>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 wrap-span pb-4 md:pb-6 lg:pb-10">
      <div v-for="(customField, key) in this.localData" :key="key" class="w-full mb-6">
        <div
          class="custom-fields w-full">
          <h3 class="font-normal text-base">
            {{ customField.label }}
          </h3>
          <p class="mt-2" v-if="customField.type !== 'address' && customField.type !== 'file'">
            <span v-if="customField.type === 'currency'">$ {{ customField.value }} </span>
            <span v-else-if="customField.type === 'number'"> {{ customField.value }} </span>
            <span v-else-if="customField.type === 'file'"> {{ customField.value }} </span>
            <span v-else-if="customField.type === 'date'"> {{ customField.value ? formattedDate(customField.value) : customField.value }} </span>
            <span v-else>{{ customField.value }}</span>
          </p>
          <S3FileGenerator v-if="customField.type === 'file'" :document="customField.value"/>
          <div v-if="customField.type === 'address'" class="mb-4">
            <p class="mt-2" v-html="formatAddress(customField.value)"></p>
          </div>
          <span v-if="customField.auditLogs && customField.auditLogs.length > 0">
            <span class="customFieldLastUpdated" @click="openPopUp([...customField.auditLogs], customField.label,customField.type)">
              Last updated on {{ getlastEditedDate(customField.auditLogs) }}
            </span>
          </span>
        </div>

        <div v-if="customField.isOpen" class="custom-fields w-full">
          <date-picker
            v-if="customField.type == 'date'"
            valueType="format"
            type="date"
            :default-value="new Date()"
            lang="en"
            class="w-auto"
            format="YYYY-MM-DD"
            v-model="customField.value"
          ></date-picker>

          <template v-else-if="customField.type === 'file'">
            <input
              :ref="`uploadImgInput${key}`"
              type="file"
              class="hidden"
              name="file"
              v-validate="'required'"
              @change="updateCurrImg($event, key)"
              accept=".pdf"
            />
            <vs-button flat class="only-border-btn small" small v-round @click="uploadImgInput(key)">
              Upload
            </vs-button>
            <span v-if="customField.file">
              {{ customField.file.name }}
            </span>
          </template>

          <vs-select v-else-if="customField.type === 'dropdown'" class="selectExample" v-model="customField.value">
            <vs-select-item
              :key="index"
              :value="item"
              :text="item"
              v-for="(item, index) in customField.dropdownSelectFields"
            />
          </vs-select>

          <template v-else-if="customField.type === 'address'">
            <label class="text-base">
              {{ customField.label }}
              <span v-if="customField.reqToSendRequest" class="mid-blue">*</span>
            </label>
            <vs-row class="my-5">
              <vs-col vs-w="7">
                <vue-google-autocomplete
                  ref="address"
                  :id="customField._id"
                  :value="productAddressFieldArray[customField._id].rawAddress"
                  classname="w-full custom-w"
                  v-on:placechanged="getAddressForProductField"
                  :country="['au']"
                  placeholder="Start typing your address"
                >
                </vue-google-autocomplete>
              </vs-col>
            </vs-row>
            <vs-row class="mb-5">
              <vs-col vs-w="12" class="custom-mt pr-xs-0 lg:mt-0 sm:mt-5">
                <label class="text-base font-light w-full">Apt, Suite, Unit, Building, Floor</label>
                <vs-input class="w-full custom-w" v-model="productAddressFieldArray[customField._id].unit_number" name="unit_number"/>
                <span class="text-danger text-sm" v-show="errors.has('unit_number')">
                  {{ errors.first("unit_number") }}
                </span>
              </vs-col>
            </vs-row>
            <vs-row class="mb-5">
              <div>
                <label class="text-base font-light">Suburb</label>
                <div class="input-container">
                  <span class="text-danger text-sm" v-show="errors.has('suburb')">{{ errors.first("suburb") }}</span>
                  <vs-input
                    class="w-full custom-w"
                    v-model="productAddressFieldArray[customField._id].suburb"
                    name="suburb"
                    v-validate="'required'"
                  />
                </div>
              </div>
            </vs-row>

            <vs-row class="mb-5">
              <vs-col vs-w="12" class="custom-mt mt-5">
                <label class="text-base font-light w-auto">Street address</label>
                <div class="input-container">
                  <span class="text-danger text-sm" v-show="errors.has('street_name')">{{ errors.first("street_name") }}</span>
                  <vs-input class="w-full custom-w add-street" v-model="productAddressFieldArray[customField._id].street_name" name="street_name" v-validate="'required'" />
                </div>
              </vs-col>
            </vs-row>

            <vs-row class="mb-5">
              <div class="custom-mt-sm pr-5 pr-xs-0">
                <label class="w-full text-base font-light">State</label>
                <div class="input-container">
                  <span class="text-danger text-sm" v-show="errors.has('state')">
                    {{ errors.first("state") }}
                  </span>
                  <vs-select
                    autocomplete
                    v-model="productAddressFieldArray[customField._id].state"
                    class="w-full custom-w"
                    name="state"
                    v-validate="'required'"
                    placeholder="Select state"
                  >
                    <vs-select-item
                      :key="index"
                      :value="item.value"
                      :text="item.text"
                      v-for="(item, index) in states"
                    ></vs-select-item>
                  </vs-select>
                </div>
              </div>
            </vs-row>

            <vs-row class="mb-5">
              <div class="custom-mt-sm">
                <label class="w-full text-base font-light">Postcode</label>
                <div class="input-container">
                  <span class="text-danger text-sm" v-show="errors.has('postcode')">{{ errors.first("postcode") }}</span>
                  <the-mask
                    class="w-full custom-w vs-input--input"
                    type="number"
                    masked
                    :mask="['####']"
                    v-model="productAddressFieldArray[customField._id].postcode"
                    name="postcode"
                    v-validate="'required'"
                  />
                </div>
              </div>
            </vs-row>
          </template>

          <money v-else-if="customField.type === 'currency'" :name="`${customField.label}`" v-model="customField.value" class="w-full" v-bind="moneyMask" />
          <vs-input v-else v-model="customField.value"></vs-input>
          <vs-row class="flex mt-2">
            <vs-button class="primary flex items-center justify-center lg:mr-8 mr-4" size="small" v-validate="'required'" @click="saveProductField(key)" v-round>Save</vs-button>
            <div class="mid-blue cursor-pointer" @click="cancelEditMode(key)">
              <u>Cancel</u>
            </div>
          </vs-row>
        </div>
      </div>
    </div>


     <!-- This is for audit log popup-->
    <vs-popup id="halomundo" :title="`${popupLabel} audit log`" :active.sync="popupActive">
      <div class="w-full">
        <vs-col vs-w="12">
          <vs-row class="flex justify-between audit-log items-start">
            <div class="timeline w-full">
              <div v-for="(log, key) in popupAuditLog" :key="key" class=" timeline-items mb-5">
                <h3 class="text-base font-normal">Updated by {{ updatedBy(log) }}</h3>
                <div class="flex mt-4">
                  <div class="con-img mr-2">
                    <S3ImageGenerator
                      v-if="log.userImage != ''"
                      :isBase64="false"
                      :document="log.userImage"
                      :customClass="'rounded-full shadow-md cursor-pointer block auditLog-icon-payment'"
                    />
                    <div v-else class="w-5 h-5">
                      <SudoImage :text="initials(log)" :isNav="true" :isPreview="false" previewImage="" />
                    </div>
                  </div>
                  <div class="w-full">
                    <p class="text-xs">{{ log.userFullname? log.userFullname: '' }}</p>
                    <h5 class="font-light text-xs">{{ log.createdDate? reFormatDate(log.createdDate): '-' }} at {{getTimeFromDate(log.createdDate)}} </h5>
                    <p class="font-light text-xs mt-3">Old Value</p>
                    <hr class="line-hr">
                    <h4 v-if="popupType=='address'" class="text-base font-normal mt-1">{{ log.oldValue ? cleanAddress(log.oldValue) : '' }}</h4>
                    <div v-else-if="popupType=='file'" class="text-base font-normal mt-1">
                      <S3FileGenerator :document="log.oldValue" />
                    </div>
                    <h4 v-else class="text-base font-normal mt-1">{{ log.oldValue? log.oldValue: '' }}</h4>
                    <p class="font-light text-xs mt-4">New Value</p>
                    <hr class="line-hr">
                      <h4 v-if="popupType=='address'" class="text-base font-normal mt-1">{{ log.newValue ? cleanAddress(log.newValue) : '' }}</h4>
                    <div v-else-if="popupType=='file'" class="text-base font-normal mt-1">
                      <S3FileGenerator :document="log.newValue" />
                    </div>
                    <h4 v-else class="text-base font-normal mt-1">{{ log.newValue ? log.newValue : '' }}</h4>
                  </div>
                </div>
              </div>
            </div>
          </vs-row>
          <vs-row class="flex justify-end">
            <vs-button v-round size="large" @click="closePopUp()">Close</vs-button>
          </vs-row>
        </vs-col>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import DatePicker from "vue2-datepicker"
import VueGoogleAutocomplete from "vue-google-autocomplete";
import moment from "moment";
import { TheMask } from "vue-the-mask";
import SudoImage from "../../../../components/SudoImage.vue"
import S3FileGenerator from "../../../../components/S3FileGenerator"
import S3ImageGenerator from "../../../../components/S3ImageGenerator"
import { Money } from "v-money";

export default {
  name: "ProductFields",
  components: {
    DatePicker,
    TheMask,
    VueGoogleAutocomplete,
    SudoImage,
    Money,
    S3FileGenerator,
    S3ImageGenerator
  },
  props: {
    productFields: { type: Array },
  },
  data() {
    return {
      localData: [],
      editIcon: require('@/assets/images/edit.png'),
      states: [
        { text: "NSW", value: "NSW" },
        { text: "ACT", value: "ACT" },
        { text: "QLD", value: "QLD" },
        { text: "VIC", value: "VIC" },
        { text: "NT", value: "NT" },
        { text: "SA", value: "SA" },
        { text: "WA", value: "WA" },
        { text: "TAS", value: "TAS" },
      ],
      prId: "",
      productAddressFieldArray: {},
      productAddressField: {
        rawAddress: "",
        index: "",
        suburb: "",
        state: "",
        unit_number: "",
        street_number: "",
        street_name: "",
        street_type: "",
      },
      popupActive: false,
      popupAuditLog: [],
      popupLabel: '',
      popupType: '',
      imagePath: process.env.VUE_APP_API_URL+'uploads/profileImage/',
      baseUrl: process.env.VUE_APP_API_URL,
      moneyMask: {
        decimal: "",
        thousands: ",",
        prefix: "$ ",
        suffix: "",
        precision: 0,
        masked: false,
      },
    };
  },
  methods: {
    ...mapActions("paymentRequest", ["saveProductFieldInPr"]),
    openEditMode(key, value, type) {
      this.localData[key].isOpen = !this.localData[key].isOpen;
      this.localData[key].oldValue = value;
    },

    cleanAddress(val) {
      let productAddressField = JSON.parse(val);

      // [StreetNo] [StreetName}, [Suburb], [State] [Postcode], [Country]
      let address ="";
      if (productAddressField.street_name) {
        address += `${productAddressField.street_name},`
      }
      if (productAddressField.suburb) {
        address+=` ${productAddressField.suburb},`
      }
      if (productAddressField.state) {
        address+=` ${productAddressField.state},`
      }
      if (productAddressField.postcode) {
        address+=` ${productAddressField.postcode},`
      }
      if (productAddressField.country) {
        address+=` ${productAddressField.country}`
      }

      return address

    },
    formatAddress(val){
      if (!val) {
        return val;
      }

      let obj = JSON.parse(val);
      let address = "";

      if (obj.unit_number && Object.keys(obj.unit_number).length > 0) {
        address = obj.unit_number + "<br>";
      }

      if (obj.street_name && Object.keys(obj.street_name).length > 0) {
        address =  address + obj.street_name + "<br>";
      } else if(obj.street && Object.keys(obj.street).length > 0) {
        address = address + obj.street + "<br>";
      }

      if (obj.suburb && Object.keys(obj.suburb).length > 0) {
        address = address + obj.suburb + " ";
      }

      if (obj.state && Object.keys(obj.state).length > 0) {
        address = address + obj.state;
        if (Object.keys(obj.postcode).length > 0) {
          address = address + " " + obj.postcode + "<br>";
        } else {
          address = address + "<br>";
        }
      }

      if (obj.country && Object.keys(obj.country).length > 0) {
        address = address + obj.country;
      } else {
        address = address + "Australia";
      }

      return address;
    },

    getAddressForProductField: function (addressData, placeResultData, _id) {
      this.addressData = addressData;

      if (this.addressData.street_number) {
        this.productAddressFieldArray[_id].street_number = this.addressData.street_number;
      }

      if (this.addressData.postal_code) {
        this.productAddressFieldArray[_id].postcode = this.addressData.postal_code;
      }

      if (placeResultData.vicinity) {
        this.productAddressFieldArray[_id].suburb = placeResultData.vicinity;
      }

      if (this.addressData.administrative_area_level_1) {
        this.productAddressFieldArray[_id].state = this.addressData.administrative_area_level_1;
      }

      if (addressData.route) {
        let street_address = "";

        for (const address of placeResultData.address_components) {
          if (address.types.includes("street_number")) {
            street_address += address.short_name + " ";
          }

          if (address.types.includes("route")) {
            street_address += address.long_name;
          }
        }

        this.productAddressFieldArray[_id].street_name = street_address;
      }
    },
    async saveProductField(key) {
      const saveData = this.localData[key];

      // stringify for address only
      if (saveData.type === "address") {
        saveData.value = JSON.stringify(this.productAddressFieldArray[saveData._id]);
      }

      saveData.paymentRequestId = this.prId;
      const fd = new FormData();
      fd.append("file", saveData.file);
      fd.append("form", JSON.stringify(saveData));

      const obj = {
        data: fd,
        config: {
          header: {
            "Content-Type": "multipart/form-data",
          },
        },
      };

      await this.saveProductFieldInPr(obj).then((response) => {
        const resultData = response.data.data;
        this.localData = resultData.map((item) => ({
          ...item,
          isOpen: false,
          oldValue: "",
        }));
      }).catch((ex) => {
        this.$vs.notify({
          title: 'Product fields',
          text: 'Unable to save product fields',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      });
    },
    cancelEditMode(key) {
      this.localData[key].isOpen = false;
    },

    uploadImgInput(key) {
      this.$refs[`uploadImgInput${key}`][0].click();
    },
    updateCurrImg(input, key) {
      this.localData[key].file = input.target.files[0];
    },
    getlastEditedDate(data){
      const lastIndex = data.length -1;
      return  moment(new Date(data[lastIndex].createdDate).toISOString()).format("DD MMM YYYY");
    },

    reFormatDate(createdDate){
      return  moment(new Date(createdDate).toISOString()).format("DD MMM YYYY");
    },

    getTimeFromDate(createdDate){
      return  moment(new Date(createdDate).toISOString()).format("HH:mm");
    },


    openPopUp(key, label,type) {
      this.popupActive = true;
      this.popupAuditLog = key;
      this.popupAuditLog = key.sort(function(a,b) {
        const str = new Date(b.createdDate);
        const cmp = new Date(a.createdDate);
        return str-cmp;
      })
      this.popupLabel = label;
      this.popupType = type;
    },
    closePopUp() {
      this.popupActive = false;
    },
    initials(log) {
      return log.userFullname ? `${log.userFullname.split(' ').slice(0, -1).join(' ').charAt(0)} ${log.userFullname.split(' ').slice(-1).join(' ').charAt(0)}` : "";
    },
    parseAddresData(address) {
      const addr = JSON.parse(address);
      return addr;
    },

    addZeroes(num) {
      num = String(num);
      if (num.length === 0) {
        return "";
      }
      return Number(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    },

    formattedDate(date) {
      return moment(date).format("DD-MMM-YYYY");
    },

    updatedBy(log) {
      let logBy = "";
      if (['Customer', 'customer'].includes(log.updateBy)) {
        logBy = log.updateBy;
      } else if (["admin", "staffAdmin", "staff", "apiUser", "introducerUser"].includes(log.updateBy)) {
        logBy = "Merchant";
      }

      return logBy;
    }
  },
  mounted() {
    this.localData = this.productFields.map((item) => ({
      ...item,
      isOpen: false,
      oldValue: "",
      file: null,
    }));

    this.prId = this.$route.params.id;

    for (let i = 0; i < this.localData.length; i++) {
      if (this.localData[i].type == "currency") {
        this.localData[i].value = this.localData[i].value.replace("$ ", "");
        this.localData[i].value = this.addZeroes(this.localData[i].value);
      }

      if (this.localData[i].type === "address") {
        this.productAddressField = this.localData[i].value ? JSON.parse(this.localData[i].value) : '';

        if (this.productAddressField) {
          this.productAddressField.rawAddress = `${this.productAddressField.street_name},${this.productAddressField.suburb} ${this.productAddressField.state}, Australia`;
          this.productAddressFieldArray[this.localData[i]._id] = this.productAddressField;
        }
      }
    }
  },
};
</script>

