<template>
  <div>
    <h3 class="font-normal text-base md:text-lg lg:text-xl title-border mb-4 md:mb-5 lg:mb-8">Admin</h3>
    <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4 wrap-span pb-4 md:pb-6 lg:pb-10">
      <div>
        <h3 class="font-normal text-base">UUID from Till</h3>
        <p class="mt-2">{{ paymentRequestDetail.uuid || "N/A" }}</p>
      </div>
      <div v-if="paymentRequestDetail.requestOptions.includes('pay-later') && (paymentRequestDetail.paymentStatus === 'Active' || paymentRequestDetail.paymentStatus === 'Extended') && productConfigType == 'THREE_PARTY'">
        <h3 class="font-normal text-base">Customer View</h3>
        <p class="mt-2">
          <a @click="redirectToCustomerPortal">View</a>
        </p>
      </div>
      <div v-if="paymentRequestDetail.selectedPaymentPlan && paymentRequestDetail.selectedPaymentPlan.maturityDate">
        <h3 class="font-normal text-base">Maturity date</h3>
        <p class="mt-2">{{ moment(paymentRequestDetail.selectedPaymentPlan.maturityDate) }}</p>
      </div>
    </div>
    <div class="grid grid-cols-3" v-if="paymentRequestDetail.requestOptions.includes('pay-later') && ['Active', 'Extended'].includes(paymentRequestDetail.paymentStatus)">
      <div class="flex mb-4" v-if="paymentRequestDetail.paymentPlan.creditDefaultCommunication.d1">
        <p class="mr-8">D1</p>
        <vs-switch v-model="paymentRequestDetail.creditDefaultCommunication.d1.enableComms" size="medium" @input="handleCommsSetting('d1')" />
      </div>
      <div class="flex mb-4" v-if="paymentRequestDetail.paymentPlan.creditDefaultCommunication.d2">
        <p class="mr-8">D2</p>
        <vs-switch v-model="paymentRequestDetail.creditDefaultCommunication.d2.enableComms" size="medium" @input="handleCommsSetting('d2')" />
      </div>
      <div class="flex mb-4" v-if="paymentRequestDetail.paymentPlan.creditDefaultCommunication.d3">
        <p class="mr-8">D3</p>
        <vs-switch v-model="paymentRequestDetail.creditDefaultCommunication.d3.enableComms" size="medium" @input="handleCommsSetting('d3')" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";

export default {
  props: {
    paymentRequestDetail: { type: Object },
    productConfigType: { type: String },
  },
  methods: {
    ...mapActions("paymentRequest", ["gotoCustomerPortal"]),

    redirectToCustomerPortal() {
      this.gotoCustomerPortal(this.paymentRequestDetail).then(res => {
        window.open(res.data.data.redirectUrl, "_blank");
      });
    },

    handleCommsSetting(commsType) {
      this.$emit("changeSettings", commsType);
    },

    moment(date) {
      return moment(date).format("DD MMM YYYY");
    },
  }
};
</script>

