<template>
  <vs-card>
    <div slot="header">
      <h3 class="font-normal text-lg md:text-xl lg:text-2xl">Request details</h3>
    </div>
    <div class="px-0 pt-0 md:pt-2 md:px-6 lg:px-8">
      <default-display-details
        :paymentRequestDetail.sync="paymentRequestDetail"
        :toggleShowMore="toggleShowMore"
        :cancelPaymentRequest="cancelPaymentRequest"
        :resendPaymentRequest="resendPaymentRequest"
        :calculations="calculations"
        :remainingAmount="remainingAmount"
        :showMore.sync="showMore"
        :updatePaymentRequest="updatePaymentRequest"
        v-on="$listeners"
        :productConfigType.sync="productConfigType"
      />
      <div v-if="showMore">
        <ProductCustomFields v-if="checkProductFieldsDisplay()" :productFields="paymentRequestDetail.productCustomFields" />
        <custom-fields v-if="checkCustomFieldsDisplay()" :customFields.sync="paymentRequestDetail.customFields" />
        <attached-files v-if="paymentRequestDetail.files.length" :files.sync="paymentRequestDetail.files" />
        <contact :paymentRequest.sync="paymentRequestDetail" v-if="productConfigType == 'THREE_PARTY'" />
        <id-verification v-if="paymentRequestDetail.idMatrixVerifications.length" :paymentRequestDetail="paymentRequestDetail"></id-verification>
        <history v-if="paymentRequestDetail.history.length" :paymentRequest.sync="paymentRequestDetail" />
        <legal v-if="showLegals" :paymentRequestDetail="paymentRequestDetail" />
        <div v-if="!paymentRequestDetail.manualPaymentByAdmin">
          <admin-extra-component :paymentRequestDetail.sync="paymentRequestDetail" :productConfigType.sync="productConfigType" v-on="$listeners" />
        </div>
      </div>
    </div>
  </vs-card>
</template>

<script>
import DefaultDisplayDetails from "./DefaultDisplayDetails.vue";
import CustomFields from "./CustomFields.vue";
import AttachedFiles from "./AttachedFiles.vue";
import Contact from "./Contact.vue";
import History from "./History.vue";
import Legal from "./Legal.vue";
import AdminExtraComponent from "./AdminExtraComponent.vue";
import ProductCustomFields from "./ProductFields.vue";
import IdVerification from "./IdVerification.vue";

export default {
  components: {
    DefaultDisplayDetails,
    CustomFields,
    AttachedFiles,
    Contact,
    History,
    Legal,
    AdminExtraComponent,
    ProductCustomFields,
    IdVerification,
  },
  props: {
    paymentRequestDetail: { type: Object },
    calculations: { type: Object },
    isAdminLogin: { type: Boolean },
    markPaymentAsCancelled: { type: Function },
    resendPaymentRequest: { type: Function },
    remainingAmount: { type: Number },
    productFields: { type: Object },
    productConfigType: { type: String },
    updatePaymentRequest: { type: Function },
  },
  data() {
    return {
      showMore: false,
    };
  },
  computed: {
    showLegals() {
      return ["Active", "Completed", "Extended"].includes(this.paymentRequestDetail.paymentStatus) && !this.paymentRequestDetail.manualPaymentByAdmin;
    }
  },
  methods: {
    toggleShowMore() {
      this.showMore = !this.showMore;
    },
    async cancelPaymentRequest() {
      return await this.markPaymentAsCancelled();
    },
    checkProductFieldsDisplay() {
      if (!this.paymentRequestDetail.productCustomFields) {
        return false;
      }

      return this.paymentRequestDetail.productCustomFields.find((customField) => { return customField; });
    },
    checkCustomFieldsDisplay() {
      if (!this.paymentRequestDetail.customFields) {
        return false;
      }

      let check = 0;
      this.paymentRequestDetail.customFields.find((item) => {
        if (item.value) {
          check++;
        }
      });
      return (check > 0);
    },
  },
};
</script>
