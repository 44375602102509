<template>
  <div>
    <div class="req-temp-container req-details" v-if="paymentRequestDetail">
      <main-details
        :paymentRequestDetail.sync="paymentRequestDetail"
        :calculations="calculations"
        :isAdminLogin.sync="isAdminLogin"
        :markPaymentAsCancelled="markPaymentAsCancelled"
        :resendPaymentRequest="resend"
        :remainingAmount="remainingAmount"
        :updatePaymentRequest="updatePRStatus"
        @update-transactions="updateTransactions"
        @pr-approvedOrDeclined="approvedOrDeclined"
        :productConfigType.sync="productConfigType"
        @updated="updateTransactions"
        @changeSettings="changeCommsSettings"
      />
      <credit-default v-if="checkCreditDisplay" :paymentRequestDetail.sync="paymentRequestDetail" :fileLodged="fileLodged" @submitNumber="updatePr" />
      <transaction @fetchData="fetchDetail" :paymentRequestDetail="paymentRequestDetail" :canRefund="canRefund" />
      <Note :paymentRequest="paymentRequestDetail" />
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import MainDetails from "./MainDetails/MainDetails";
import Transaction from "./transaction.vue";
import CreditDefault from "./creditDefault.vue";
import { EventBus } from "../../../../components/event-bus";
import { sentryLog } from "../../../../helper/sentryLog";
import Note from "./notes";
import { RRule } from "rrule";
import moment from "moment-timezone";

export default {
  components: {
    MainDetails,
    Transaction,
    CreditDefault,
    Note,
  },
  data() {
    return {
      paymentRequestDetail: {},
      showMore: false,
      calculations: null,
      isAdminLogin: false,
      remainingAmount: 0,
      productFieldsDetail: {},
      partnerId: JSON.parse(localStorage.getItem("user"))._id,
      canRefund: false,
      paymentStatus: "",
      fileLodged: false,
      productConfigType: "",
      paymentSchedules: "",
    };
  },

  methods: {
    ...mapActions("paymentRequest", [
      "fetchPaymentRequestDetailById",
      "cancelPaymentRequest",
      "resendPaymentRequest",
      "updatePRData",
      "updatePaymentRequest",
      "updatePaymentRequestCommunication",
    ]),

    ...mapActions("payment", ["getQuotationDataForPayLater"]),

    updateTransactions() {
      this.fetchDetail(this.$route.params.id);
    },

    prepareSchedule() {
      let rule = {
        dtstart: new Date(this.paymentRequestDetail.frequency.startDate),
        freq: this.paymentRequestDetail.frequency.every === "week" ? RRule.WEEKLY : RRule.MONTHLY,
        interval: parseInt(this.paymentRequestDetail.frequency.interval),
      };

      if (this.paymentRequestDetail.frequency.endType === "by") {
        rule.until = this.paymentRequestDetail.frequency.endDate ? this.paymentRequestDetail.frequency.endDate : new Date();
      } else {
        rule.count = this.paymentRequestDetail.frequency.endAfterNumberOfPayments ? parseInt(this.paymentRequestDetail.frequency.endAfterNumberOfPayments) : 1;
      }

      this.installment(rule);
    },

    installment(payload) {
      const rule = new RRule(payload);
      this.paymentSchedules = rule.all();
    },

    async fetchDetail(id) {
      this.$vs.loading();

      await this.fetchPaymentRequestDetailById(id).then((result) => {
        this.$vs.loading.close();
        this.paymentRequestDetail = result.data.data;
        this.productConfigType = this.$lodashHelper.checkObjectKey(this.paymentRequestDetail, "payLaterPlan[0].currentPlan.productConfigType", "THREE_PARTY");
        let pageType = [];
        let paymentRequestTemplate = this.$lodashHelper.checkObjectKey(this.paymentRequestDetail, "paymentRequestTemplate", []);
        this.paymentStatus = this.$lodashHelper.checkObjectKey(this.paymentRequestDetail, "paymentStatus", "");
        let creditLodgementNumber = this.$lodashHelper.checkObjectKey(this.paymentRequestDetail, "creditLodgementNumber", "");

        if (this.creditLodgementAllowed && creditLodgementNumber) {
          this.fileLodged = true;
        }

        if (paymentRequestTemplate.length > 0 && this.paymentRequestDetail.paymentRequestTemplate[0].requestType === "template") {
          pageType = this.paymentRequestDetail.groupAccessTemplate;
        } else {
          pageType = this.paymentRequestDetail.groupAccessHosted;
        }

        if (pageType && pageType.length > 0) {
          const groupUserAccess = pageType[0];
          if (groupUserAccess.userAccess && groupUserAccess.userAccess.length > 0) {
            const objIndex = groupUserAccess.userAccess.findIndex((obj) => obj.user == this.partnerId);
            if (objIndex >= 0) {
              this.canRefund = groupUserAccess.userAccess[objIndex].canRefund;
            }
          }
        }

        if (this.paymentRequestDetail.requestOptions.includes("pay-later")) {
          this.getCalculations();
        }

        if (this.paymentRequestDetail.requestType === "recurring") {
          this.prepareSchedule();
        }

        if (!this.paymentRequestDetail.selectedPaymentPlan && this.paymentRequestDetail.transactions) {
          const refundedTransactions = this.paymentRequestDetail.transactions.filter((transaction) => { return transaction.paymentStatus === "Refunded"; });
          const actualTransaction = this.paymentRequestDetail.transactions.find((transaction) => { return transaction.paymentStatus === "Completed"; });

          if (refundedTransactions.length) {
            this.refundedAmount = refundedTransactions.map((item) => Math.abs(parseFloat(item.amount))).reduce((prev, next) => prev + next, 0);
            this.remainingAmount = parseFloat(actualTransaction.amount) - this.refundedAmount;
          }
        }
      });
      this.checkIfAdminLogin();
    },

    getCalculations() {
      this.getQuotationDataForPayLater({ paymentRequestId: this.paymentRequestDetail._id }).then((result) => {
        const total = result.data.data.find(item => item.type == "Total");
        const disbursement = result.data.data.find(item => item.type == "Disbursement");
        const schedule = result.data.data.filter(item => item.type == "Payment");

        this.calculations = {
          total,
          disbursement,
          schedule
        };
      });
    },

    toggleShowMore() {
      this.showMore = !this.showMore;
    },

    checkIfAdminLogin() {
      let loggedUser = this.$store.state.AppActiveUser;

      if (loggedUser.adminId && loggedUser.adminId != "" && loggedUser.loginBy === "flip admin") {
        this.isAdminLogin = true;
      }
    },

    async markPaymentAsCancelled() {
      let data = {
        id: this.$route.params.id,
        data: { flipAdminId: this.user._id },
      };

      return await this.cancelPaymentRequest(data);
    },

    async resend() {
      let data = {
        id: this.$route.params.id,
        data: { flipAdminId: this.user._id, sendOnlyComms: this.paymentRequestDetail.paymentStatus == "Pending" },
      };

      return await this.resendPaymentRequest(data).then((response) => {
        if (response.data) {
          this.showSuccessMessage("Payment reques", "Payment request resent successfully");
        } else {
          this.showErrorMessage("Payment request", "We are unable to process your request at this time.");
        }

        this.$vs.loading.close();
        this.$router.push({ name: "payment-activity" });
      }).catch((ex) => {
        this.showErrorMessage("Payment request", "We are unable to process your request at this time.");
        this.$router.push({ name: "payment-activity" });
      });
    },

    approvedOrDeclined() {
      this.fetchDetail(this.$route.params.id);
    },

    async updatePr(fileNumber) {
      let data = {
        id: this.$route.params.id,
        payload: { creditLodgementNumber: fileNumber },
      };
      this.$vs.loading();

      return await this.updatePRData(data).then((result) => {
        let creditLodgementNumber = this.$lodashHelper.checkObjectKey(result.data.data, "creditLodgementNumber", "");

        if (this.creditLodgementAllowed && creditLodgementNumber) {
          this.fileLodged = true;
          this.paymentRequestDetail.creditLodgementNumber = creditLodgementNumber;
        }

        this.showSuccessMessage("Credit lodgement file number", "Credit lodgement file number has been added.");
        this.$vs.loading.close();
      }).catch(ex => {
        sentryLog(ex);
        this.$vs.loading.close();
        this.showErrorMessage("Credit lodgement file number", "Something went wrong. Please try again later.");
      });
    },

    async updatePRStatus() {
      const data = { id: this.$route.params.id };
      this.$vs.loading();

      return await this.updatePaymentRequest(data).then((response) => {
        this.$vs.loading.close();
        if (response.data) {
          this.showSuccessMessage("Payment request", "Payment request completed successfully");
        } else {
          this.showErrorMessage("Payment request", "We are unable to process your request at this time");
        }
        this.fetchDetail(this.$route.params.id);
      }).catch((ex) => {
        this.$vs.loading.close();
        this.showErrorMessage("Payment request", "We are unable to process your request at this time");
      });
    },

    showSuccessMessage(title = "Success", message = "Success") {
      this.$toast.success(<div class="container"><h3>{title}</h3><p>{message}</p></div>, {
        position: "top-right",
        timeout: 5000,
        closeOnClick: false,
        pauseOnFocusLoss: false,
        pauseOnHover: false,
        draggable: false,
        draggablePercent: 0.1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: {
          iconClass: "material-icons text-success",
          iconChildren: "check_circle_outline",
          iconTag: "span"
        },
        rtl: false,
        message: message
      });
    },

    showErrorMessage(title = "Error", message = "Something went wrong", time = false) {
      this.$toast.error(<div class="container"><h3>{title}</h3><p>{message}</p></div>, {
        position: "top-right",
        timeout: time ? 3000 : false,
        closeOnClick: false,
        pauseOnFocusLoss: false,
        pauseOnHover: false,
        draggable: false,
        draggablePercent: 0.1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: {
          iconClass: "material-icons text-danger",
          iconChildren: "highlight_off",
          iconTag: "span"
        },
        rtl: false,
        message: message
      });
    },

    async changeCommsSettings(commsType) {
      let payload;

      if (commsType == "d1") {
        payload = { "creditDefaultCommunication.d1.enableComms" : this.paymentRequestDetail.creditDefaultCommunication[commsType].enableComms };
      } else if (commsType == "d2") {
        payload = { "creditDefaultCommunication.d2.enableComms" : this.paymentRequestDetail.creditDefaultCommunication[commsType].enableComms };
      } else if (commsType == "d3") {
        payload = { "creditDefaultCommunication.d3.enableComms" : this.paymentRequestDetail.creditDefaultCommunication[commsType].enableComms };
      }

      const data = {
        id: this.$route.params.id,
        commsType,
        ...payload
      };

      this.$vs.loading();

      return await this.updatePaymentRequestCommunication(data).then((response) => {
        this.$vs.loading.close();
        if (response.data) {
          this.showSuccessMessage("Payment request", "Payment request settings updated successfully");
        } else {
          this.showErrorMessage("Payment request", "We are unable to process your request at this time");
        }
      }).catch((ex) => {
        this.$vs.loading.close();
        this.showErrorMessage("Payment request", "We are unable to process your request at this time");
      });
    }
  },

  mounted() {
    this.fetchDetail(this.$route.params.id);
  },

  created() {
    EventBus.$on("registerBankTransferSuccess", value => {
      this.fetchDetail(this.$route.params.id);
    });
  },

  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },

    checkCreditDisplay() {
      return this.paymentStatus === "Active" && this.creditLodgementAllowed;
    },

    creditLodgementAllowed() {
      if (!this.paymentRequestDetail || !this.paymentRequestDetail.selectedPaymentPlan) {
        return false;
      }

      const creditLodgementNumber = this.$lodashHelper.checkObjectKey(this.paymentRequestDetail, "creditLodgementNumber", false);
      if (creditLodgementNumber) {
        return true;
      }

      let displayCreditDefault = false;
      const creditLodgementDate = moment(this.paymentRequestDetail.selectedPaymentPlan.maturityDate).add(60, "days").format("YYYY-MM-DD");
      displayCreditDefault = (moment().tz("Australia/Sydney").format("YYYY-MM-DD") >= creditLodgementDate);

      if (displayCreditDefault) {
        displayCreditDefault = (this.paymentRequestDetail.creditDefaultCommunication && this.paymentRequestDetail.creditDefaultCommunication.d3 && this.paymentRequestDetail.creditDefaultCommunication.d3.commsSentAt) ? true : false;
      }

      return displayCreditDefault;
    }
  },
};
</script>

