<template>
  <vs-card>
    <div slot="header">
      <h3 class="font-normal text-lg md:text-xl lg:text-2xl">Credit default</h3>
    </div>
    <div class="px-0 pt-0 md:pt-2">
      <p class="text-base">This payment request is in default. Enter the credit lodgement file number to send credit default communications to the borrower.</p>
    </div>
    <vs-row class="pt-6">
      <vs-col class="pr-6" vs-w="4" vs-xs="12">
        <div class="input-grp">
          <label class="w-full text-xm font-normal">Credit lodgement file number</label>
          <vs-input v-if="!fileLodged" v-model="creditLodgementNumber" v-validate="'creditDefaultFormat|max:9'" maxLength="9" data-vv-as="File number" class="w-full" name="creditLodgementNumber" placeholder="Required" size="large" />
          <p class="pt-2" v-else>{{ paymentRequestDetail.creditLodgementNumber }}</p>
          <span v-show="errors.has('creditLodgementNumber')" class="text-danger text-sm">{{ errors.first("creditLodgementNumber") }}</span>
        </div>
      </vs-col>
      <vs-col vs-w="4" vs-xs="4" class="pt-3">
        <vs-button flat v-round size="medium" :disabled="!checkCommsSubmit" v-if="!fileLodged" @click="submitComms">Send communications</vs-button> 
      </vs-col>
    </vs-row>
  </vs-card>
</template>

<script>
export default {
  props: {
    paymentRequestDetail: { type: Object },
    fileLodged: { type: Boolean },
  },
  data() {
    return {
      creditLodgementNumber: "",
    };
  },
  methods: {
     updatePageData() {
      if (this.paymentRequestDetail.creditLodgementNumber) {
        this.creditLodgementNumber = this.paymentRequestDetail.creditLodgementNumber;
      }
    },
    submitComms() {
      const validation = this.$validator.validateAll();
      if (!validation) {
        return false;
      }
      this.$emit('submitNumber', this.creditLodgementNumber);
    }
  },
  watch: {
    paymentRequestDetail(newVal) {
      if (newVal) {
        this.updatePageData();
      }
    }
  },
  computed: {
    checkCommsSubmit() {
      return this.creditLodgementNumber && !this.errors.any();
    }
  },
  created() {
    this.$validator.extend("creditDefaultFormat", {
      getMessage: () => "The credit lodgement number must be alphanumeric and 9 characters.",
      validate: (value) => value.match(/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+){9}$/g) !== null,
    });
  }
};
</script>
